import { addMinutes, differenceInMinutes, differenceInSeconds, intervalToDuration, isPast } from 'date-fns';
import { t } from 'i18next';

import { AttemptStatus } from '@coco/types/deliveries';

import { COMMON_KEYS, DELIVERY_STATUS_KEYS, LOC_NS } from 'src/i18n/types';
import { DeliveryOperationalMode, ListDelivery, Status, StatusType, Trip, TripType } from '../@types';
import { calculateTimeCopy, commonT, deliveryStatusT, durationToTimeCopy } from './delivery-shared';
import { localeFormatDate } from './utils';

const ONE_MINUTE_IN_SECONDS = 60;
const THREE_MINUTES_IN_SECONDS = 180;
const FIVE_MINUTES_IN_SECONDS = 300;

interface LoadTimeResponse {
  loadTimeExceeded: boolean;
  loadTimeCopy: string;
}

const calculateLoadTime = (timeLoadRequested: Date, expectedLoadTimeMin = 0): LoadTimeResponse => {
  let loadTimeExceeded = false;
  let loadTimeCopy = '';

  const now = new Date();
  if (
    expectedLoadTimeMin === 0 ||
    (expectedLoadTimeMin > 0 && differenceInMinutes(now, timeLoadRequested) > expectedLoadTimeMin)
  ) {
    loadTimeExceeded = true;
  }

  const duration = intervalToDuration({
    start: loadTimeExceeded ? addMinutes(timeLoadRequested, expectedLoadTimeMin) : timeLoadRequested,
    end: now,
  });

  loadTimeCopy = `${durationToTimeCopy(duration)} ${commonT(COMMON_KEYS.AGO, 'ago')}`;

  return {
    loadTimeExceeded,
    loadTimeCopy,
  };
};

export const calculateDynamicEtaUntil = (eta: Date) => {
  if (isPast(eta)) {
    return deliveryStatusT(DELIVERY_STATUS_KEYS.ARRIVING_SOON, 'Arriving soon');
  }

  const duration = intervalToDuration({
    start: new Date(),
    end: eta,
  });

  return `${durationToTimeCopy(duration)} ${deliveryStatusT(DELIVERY_STATUS_KEYS.TO_DEST, 'to dest')}`;
};

export const calculateDynamicEtaCopy = (
  eta: string,
  targetDropoffTime: string
): { dynamicEtaCopy: string; dynamicEtaToCopy: string } => {
  const estimatedEta = new Date(targetDropoffTime);
  const actualEta = new Date(eta);
  const dateToCopy = (date: Date) => ({
    dynamicEtaCopy: `${t(`${LOC_NS.DELIVERY_STATUS}: ${DELIVERY_STATUS_KEYS.ETA}`, 'ETA')}: ${localeFormatDate(
      date,
      'h:mm a'
    )}`,
    dynamicEtaToCopy: calculateDynamicEtaUntil(date),
  });

  if (isPast(actualEta) || Math.abs(differenceInSeconds(actualEta, new Date())) <= ONE_MINUTE_IN_SECONDS) {
    // order arriving in 1 minute or less, show static eta, also handle situations where eta is in the past
    return dateToCopy(addMinutes(new Date(), 1));
  }

  const actualToEstimatedSeconds = differenceInSeconds(actualEta, estimatedEta);
  if (actualToEstimatedSeconds >= -THREE_MINUTES_IN_SECONDS && actualToEstimatedSeconds <= FIVE_MINUTES_IN_SECONDS) {
    // order between 3 minutes early and 5 minutes late, show (estimated eta + 5 min)
    return dateToCopy(addMinutes(estimatedEta, 5));
  }

  return dateToCopy(estimatedEta);
};

export const getRobotStatus = (
  currentAttemptStatus: ListDelivery['currentAttemptStatus'],
  statusTimestampsMap: ListDelivery['statusTimestampsMap'],
  expectedLoadTimeMin: number,
  operationalMode: ListDelivery['operationalMode'],
  updatedAt: string,
  currentTrip?: Trip
): Status => {
  /* Delivery is in its JITP phase */
  if (currentTrip?.type === TripType.JITP) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_PICKUP, 'On the way to pickup'),
      timeCopy: '',
      statusType: StatusType.ON_THE_WAY_TO_PICKUP,
    };
  }

  /* Preload */
  if (
    statusTimestampsMap?.Requested &&
    !!currentAttemptStatus &&
    ([AttemptStatus.Pending, AttemptStatus.Requested, AttemptStatus.AtPickup] as AttemptStatus[]).includes(
      currentAttemptStatus
    )
  ) {
    const { loadTimeExceeded, loadTimeCopy } = calculateLoadTime(
      new Date(statusTimestampsMap.Requested),
      expectedLoadTimeMin
    );
    const showLoadTimeExceeded = loadTimeExceeded && currentAttemptStatus === AttemptStatus.AtPickup;
    return {
      statusCopy: showLoadTimeExceeded
        ? deliveryStatusT(DELIVERY_STATUS_KEYS.LOAD_TIME_EXCEEDED, 'Load Time Exceeded')
        : currentAttemptStatus === AttemptStatus.AtPickup
        ? deliveryStatusT(DELIVERY_STATUS_KEYS.ROBOT_IS_OUTSIDE, 'Robot is outside')
        : deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_PICKUP, 'On the way to pickup'),
      timeCopy: loadTimeCopy,
      statusType: showLoadTimeExceeded ? StatusType.LATE_LOADED : StatusType.ON_THE_WAY_TO_PICKUP,
    };
  }

  /* Order canceled through DP */
  if (statusTimestampsMap?.Canceled && currentAttemptStatus === AttemptStatus.Canceled) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Canceled))}`,
      statusType: StatusType.CANCELED,
    };
  }

  /* Order canceled through 3rd party */
  if (operationalMode === DeliveryOperationalMode.Canceled && updatedAt) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(updatedAt))}`,
      statusType: StatusType.CANCELED,
    };
  }

  return {
    statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.FULFILLED, 'Fulfilled'),
    statusType: StatusType.FULFILLED,
  };
};
