import styled from '@emotion/styled';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Container = styled.div`
  max-width: 1000px;
  margin: 24px auto;
`;

const PageContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default PageContainer;
