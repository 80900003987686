import { AxiosInstance } from 'axios';
import { differenceInMinutes, formatDuration, intervalToDuration, isSameDay } from 'date-fns';
import { t } from 'i18next';

import { AttemptStatus, DeliveryCancelReason } from '@coco/types/deliveries';

import { DELIVERY_STATUS_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { getPII } from 'src/service';
import { AttemptProvider, DeliveryOperationalMode, ERROR, ListDelivery, Status, StatusType, Trip } from '../@types';
import { getDoorDashStatus } from './delivery-doordash';
import { getRobotStatus } from './delivery-robot';
import { calculateTimeCopy, deliveryStatusT } from './delivery-shared';
import { logError } from './logging';
import { getDateFnsLocale, localeFormatDate } from './utils';

export const ordersT = (key: ORDERS_KEYS, fallback: string) => {
  return t(`${LOC_NS.ORDERS}:${key}`, fallback);
};

const getRescueStatus = (
  currentAttemptStatus: ListDelivery['currentAttemptStatus'],
  statusTimestampsMap: ListDelivery['statusTimestampsMap'],
  operationalMode: ListDelivery['operationalMode'],
  updatedAt: string
): Status => {
  /* Order canceled */
  if (statusTimestampsMap?.Canceled && currentAttemptStatus === AttemptStatus.Canceled) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Canceled))}`,
      statusType: StatusType.CANCELED,
    };
  }

  /* Order canceled through 3rd party */
  if (operationalMode === DeliveryOperationalMode.Canceled && updatedAt) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(updatedAt))}`,
      statusType: StatusType.CANCELED,
    };
  }

  return {
    statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.FULFILLED, 'Fulfilled'),
    statusType: StatusType.FULFILLED,
  };
};

const getScheduledStatus = (scheduledFor: string): Status => {
  const scheduledForDate = new Date(scheduledFor);

  const scheduledDateIsToday = isSameDay(new Date(), scheduledForDate);

  return {
    statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.SCHEDULED_FOR, 'Scheduled for'),
    timeCopy: localeFormatDate(new Date(scheduledFor), scheduledDateIsToday ? 'p' : 'PPp'),
    statusType: StatusType.SCHEDULED,
  };
};

export const getStatus = (delivery: ListDelivery, currentTrip?: Trip): Status => {
  const {
    currentAttemptStatus,
    statusTimestampsMap,
    providedBy,
    eta,
    expectedLoadTimeMin,
    scheduledFor,
    deliveryMedium,
    operationalMode,
    updatedAt,
  } = delivery;

  if (!currentAttemptStatus && scheduledFor) {
    return getScheduledStatus(scheduledFor);
  }

  if (providedBy === AttemptProvider.ROBOT) {
    return getRobotStatus(
      currentAttemptStatus,
      statusTimestampsMap,
      expectedLoadTimeMin,
      operationalMode,
      updatedAt,
      currentTrip
    );
  }

  if (providedBy === AttemptProvider.DOOR_DASH) {
    return getDoorDashStatus(
      currentAttemptStatus,
      statusTimestampsMap,
      deliveryMedium,
      eta,
      operationalMode,
      updatedAt
    );
  }

  if (providedBy === AttemptProvider.UBER) {
    return getRescueStatus(currentAttemptStatus, statusTimestampsMap, operationalMode, updatedAt);
  }

  logError(`Unhandled Provider: ${providedBy}`, ERROR.UNKNOWN_PROVIDER, delivery);
  return {
    statusCopy: '',
    statusType: StatusType.ON_THE_WAY_TO_PICKUP,
  };
};

export const getRobotEtaCopy = (estPickupTime: ListDelivery['estPickupTime']): string | null => {
  if (!estPickupTime) {
    logError({ message: 'missing estPickupTime' }, ERROR.MISSING_ESTIMATED_PICKUP_TIME);
    return null;
  } else if (new Date(estPickupTime) < new Date()) {
    logError({ message: 'past estPickupTime' }, ERROR.PAST_ESTIMATED_PICKUP_TIME);
    return null;
  } else if (differenceInMinutes(new Date(estPickupTime), new Date()) >= 30) {
    logError({ message: 'estPickupTime over 30 minutes' }, ERROR.ESTIMATED_PICKUP_OVER_30_MINUTES);
    return null;
  } else {
    const duration = intervalToDuration({
      start: new Date(),
      end: new Date(estPickupTime),
    });
    const formattedDuration = formatDuration(duration, { locale: getDateFnsLocale(), format: ['hours', 'minutes'] });
    return formattedDuration === ''
      ? ordersT(ORDERS_KEYS.ARRIVING_NOW, 'Arriving now')
      : `${ordersT(ORDERS_KEYS.ARRIVES_IN, 'Arrives in')} ${formattedDuration}`;
  }
};

export const sortActiveDeliveries = (deliveries: ListDelivery[] = [], maxCanceledAgeMins = 10): ListDelivery[] => {
  const deliveriesLateLoaded: ListDelivery[] = [];
  const deliveriesScheduled: ListDelivery[] = [];
  const deliveriesAtCustomer: ListDelivery[] = [];
  const deliveriesInTransit: ListDelivery[] = [];
  const deliveriesWaitingForLoad: ListDelivery[] = [];
  const deliveriesCanceled: ListDelivery[] = [];
  const deliveriesFulfilled: ListDelivery[] = [];

  deliveries.forEach((delivery: ListDelivery) => {
    if (
      delivery.operationalMode === DeliveryOperationalMode.Canceled ||
      delivery.currentAttemptStatus === AttemptStatus.Canceled
    ) {
      if (
        // only keep deliveries canceled within the past 10 minutes
        differenceInMinutes(new Date(), new Date(delivery.updatedAt)) <= maxCanceledAgeMins &&
        // merchants don't care about orders that have already been handed off or loaded
        !delivery?.statusTimestampsMap?.InTransit &&
        // merchants don't care about duplicate or test orders
        !(delivery?.cancelReason === DeliveryCancelReason.TestOrder) &&
        !(delivery?.cancelReason === DeliveryCancelReason.DuplicateOrder) &&
        !(delivery?.cancelReason === DeliveryCancelReason.MerchantRequestedDuplicateOrder)
      ) {
        deliveriesCanceled.push(delivery);
      }
    } else {
      const { statusType } = getStatus(delivery);
      switch (statusType) {
        case StatusType.LATE_LOADED:
          deliveriesLateLoaded.push(delivery);
          break;
        case StatusType.IN_TRANSIT:
          deliveriesInTransit.push(delivery);
          break;
        case StatusType.AT_CUSTOMER:
          deliveriesAtCustomer.push(delivery);
          break;
        case StatusType.SCHEDULED:
          deliveriesScheduled.push(delivery);
          break;
        case StatusType.FULFILLED:
          deliveriesFulfilled.push(delivery);
          break;
        default:
          deliveriesWaitingForLoad.push(delivery);
      }
    }
  });

  deliveriesLateLoaded.sort((deliveryA, deliveryB) => {
    // sort late deliveries in descending order
    return new Date(deliveryA.createdAt).valueOf() - new Date(deliveryB.createdAt).valueOf();
  });

  deliveriesScheduled.sort((deliveryA, deliveryB) => {
    // sort scheduled deliveries in ascending order
    return new Date(deliveryB.createdAt).valueOf() - new Date(deliveryA.createdAt).valueOf();
  });

  deliveriesWaitingForLoad.sort((deliveryA, deliveryB) => {
    // sort pending deliveries in descending order
    return new Date(deliveryA.createdAt).valueOf() - new Date(deliveryB.createdAt).valueOf();
  });

  deliveriesCanceled.sort((deliveryA, deliveryB) => {
    // sort canceled deliveries by most recently updated
    return new Date(deliveryB.updatedAt).valueOf() - new Date(deliveryA.updatedAt).valueOf();
  });

  return [
    ...deliveriesLateLoaded,
    ...deliveriesWaitingForLoad,
    ...deliveriesInTransit,
    ...deliveriesAtCustomer,
    ...deliveriesFulfilled,
    ...deliveriesScheduled,
    ...deliveriesCanceled,
  ];
};

export const lateLoadedDeliveryExists = (deliveries: ListDelivery[] = []): boolean => {
  return !!deliveries.find((delivery: ListDelivery) => {
    const { statusType } = getStatus(delivery);
    return statusType === StatusType.LATE_LOADED;
  });
};

export const getLateLoadedDeliveries = (deliveries: ListDelivery[] = []): ListDelivery[] => {
  return deliveries.filter((delivery: ListDelivery) => {
    const { statusType } = getStatus(delivery);
    return statusType === StatusType.LATE_LOADED && delivery.currentAttemptStatus === AttemptStatus.AtPickup;
  });
};

export const populateDeliveriesWithPII = async (
  deliveries: ListDelivery[],
  privacyApi: AxiosInstance
): Promise<ListDelivery[]> => {
  return Promise.all(
    deliveries.map(async (delivery: ListDelivery) => {
      if (delivery.customerId && delivery.customerName === 'REDACTED') {
        try {
          const PII = await getPII(privacyApi, delivery.customerId);
          if (!!PII?.name) {
            return {
              ...delivery,
              customerName: PII.name,
            };
          }
        } catch (err) {
          console.error(err);
        }
      }
      return delivery;
    })
  );
};

// until we can rely on the AtPickup attempt status, we need to correct the case where the robot that triggered the AtPickup status is no longer assigned to the delivery
export const correctAtPickupAttemptStatuses = (deliveries: ListDelivery[]): ListDelivery[] => {
  return deliveries.map((delivery: ListDelivery) => {
    const { providedBy, currentAttemptStatus, estPickupTime } = delivery;

    if (providedBy === AttemptProvider.ROBOT && currentAttemptStatus === AttemptStatus.AtPickup) {
      // the robot can only truly be at pickup if the estimated pickup time is in the past (discussion in DP-1451)
      const robotAtPickup = !!estPickupTime && new Date(estPickupTime) < new Date();
      if (!robotAtPickup) {
        return {
          ...delivery,
          currentAttemptStatus: AttemptStatus.Pending,
        };
      }
    }

    return delivery;
  });
};

export const cancelableAttemptStatuses: AttemptStatus[] = [
  AttemptStatus.Requested,
  AttemptStatus.Pending,
  AttemptStatus.RequestFailed,
  AttemptStatus.Scheduled,
  AttemptStatus.Assigned,
  AttemptStatus.AtPickup,
];
